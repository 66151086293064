import Apaexlinecolumn from "components/apaexlinecolumn";
import { get } from "helpers/api_helper";
import { DASHBOARD, DEVICE } from 'helpers/url_helper';
import React, { useEffect, useState, useMemo } from "react";
import MetaTags from 'react-meta-tags';
import {
  Card,
  CardBody,
  CardTitle,
  Container,
  Modal
} from "reactstrap";
import LiveViewMap from "./liveview_component";
import MiniCard from "./mini_cards";
import moment from "moment";
import MapComponent from "./map_component";

const  Dashboard = () => {
  const [devices, setDevices] = useState(null);
  const [filterDate, setFilterDate] = useState(moment().format('YYYY-MM-DD'));
  const [cardCounts, setCardCounts] = useState(null);
  const [attendanceByDate, setAttendanceByDate] = useState(null);
  const [isActiveMenu, setIsActiveMenu] = useState(0);
  const [isOpenLogModal, setIsOpenLogModal] = useState(false);
  const [modalLogData, setModalLogData] = useState({});
  const [logDataWithAddress, setLogDataWithAddress] = useState([]);
  
  // Function to fetch data from the server based on the deviceID (optional)
  const fetchData = async (deviceID = false, isDelete=false) => {
      try {
          const res = await get(`${DEVICE}`);
          if(res.success){
            setDevices(res?.body?.rows);
          }
          
          const count = await get(`${DASHBOARD}?_q=${filterDate}`);
          if(count?.success){
            setCardCounts(count?.body)
          }

          const attendanceByDate = await get(`${DASHBOARD}/attendance/${filterDate}`);
          if(attendanceByDate?.success){
            setAttendanceByDate(attendanceByDate?.body)
          }
          
      } catch (error) {
          console.error("Error fetching data:", error);
      }
  };

  const fetchLogsData = async () => {
      try {
        const attendanceLogsByDate = await get(`${DASHBOARD}/attendance/logs/${isOpenLogModal}/${filterDate}`);
        if(attendanceLogsByDate?.success){
          console.log(attendanceLogsByDate?.body)
          setModalLogData(attendanceLogsByDate?.body)
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
  };
  // Fetch initial data when the component mounts
  // useEffect(() => { fetchData(); }, []);
  useEffect(() => { fetchData(); }, [filterDate]);
  useEffect(() => { fetchLogsData(); }, [isOpenLogModal]);

  // Memorize processed log data (if any processing is needed)
  const processedLogData = useMemo(() => modalLogData, [modalLogData]);

  const getAddress = async (location) =>{
    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${location}&key=AIzaSyBLeOI-YlGX2omhOYgRhNLf6zp3yI9RKFw`;
    fetch(url)
    .then(response => response.json())
    .then(data => {
      const firstResult = data?.results[0];
      if (firstResult) {
        return firstResult.formatted_address;
      } else {
        return 'Address not found.';
      }
    })
    .catch(error => {
      setAddress('Error fetching data');
      console.error('Error fetching data:', error);
    });
  } 

  // const fetchLogAddresses = async () => {
  //   if (!processedLogData?.logs) return;
  //   const logsWithAddress = await Promise.all(
  //     processedLogData.logs.map(async (log) => {
  //       const address = await getAddress(log.location);
  //       return { ...log, address }; // Append address to each log item
  //     })
  //   );
  //   setLogDataWithAddress(logsWithAddress); // Set the fetched logs with addresses in state
  // };

  // useEffect(() => {
  //   fetchLogAddresses();
  // }, [processedLogData]);

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Dashboard | Field Tracking</title>
        </MetaTags>
        <Container fluid>
          <div className="row">
            <div className="col-xl-6">
              <MiniCard data={cardCounts} byDateFilter={(e) => setFilterDate(e)}/>

              <div className="row">
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4"> {moment().format('MMMM - YYYY')}: Attendance</CardTitle>
                    <Apaexlinecolumn />
                    <hr />
         
                    <ul className="bg-light rounded nav nav-pills">
                      <li className="nav-item"><a className={`${isActiveMenu == 0?'active':''} nav-link`} onClick={() => setIsActiveMenu(0)}>Present</a></li>
                      <li className="nav-item"><a className={`${isActiveMenu == 1?'active':''} nav-link`} onClick={() => setIsActiveMenu(1)}>Absent</a></li>
                    </ul>
                    
                    <div className="mt-4">
                      <div style={{height: '260px', overflowX: 'scroll'}}>
                        <table className="table table-nowrap align-middle table-hover mb-0 table">
                            <tbody>
                              {
                                isActiveMenu == 0 ? 
                                (
                                  attendanceByDate?.present?.length ? attendanceByDate?.present?.map((v, i) => <tr key={`PAEL_${i+1}`}>
                                        <td>
                                          <h5 className="text-truncate font-size-14 mb-1"><a className="text-dark" href="/dashboard-saas">{`${v['Employee.firstname']} ${v['Employee.lastname']} (${v['Employee.unique_id']})`}</a></h5>
                                          <span className="mb-0 text-success">{v.check_in}</span> - <span className="text-danger mb-0">{v.check_out || '****'}</span>
                                        </td>
                                        <td className="text-end">
                                          <button className="btn btn-primary" onClick={() => setIsOpenLogModal(v['Employee.unique_id'])}>View</button>
                                        </td>
                                    </tr>) : <tr><td>No Attendance Logs Recorded.</td></tr>
                                ) : (
                                  attendanceByDate?.absent?.map((v, i) => <tr key={`AAEL_${i+1}`}>
                                      <td>
                                        <h5 className="text-truncate font-size-14 mb-1"><a className="text-dark" href="#">{`${v.firstname} ${v.lastname} (${v.unique_id})`}</a></h5>
                                      </td>
                                  </tr>)
                                )
                              }
                            </tbody>
                        </table>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </div>
              {/* <div className="row">
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4"> Team wise Attendance Report </CardTitle>
                    <Apaexlinecolumn type={'team'}/>
                  </CardBody>
                </Card>
              </div> */}
            </div>

            <div className="col-xl-6">
              <LiveViewMap devices={devices}/>
            </div>
          </div>

          <Modal
            isOpen={isOpenLogModal != false}
            centered={true}
          >
            <div className="modal-header">
                <h5 className="modal-title mt-0">Empoyee tracking logs</h5>
                <button
                    type="button"
                    onClick={() => setIsOpenLogModal(false)}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">
              <div className="row" style={{height: '512px', overflowX: 'scroll'}}>

                {
                  console.log(processedLogData?.logs?.length)
                }

                {
                  processedLogData?.logs?.length && 
                  <MapComponent data={processedLogData.logs} />
                }

                {/* <ul className="verti-timeline list-unstyled">
                  {processedLogData.map((log, i) => (
                    <li className={`event-list ${i === 0 ? 'active' : ''}`} key={`LMK_${i + 1}`}>
                      <div className="event-timeline-dot">
                        <i className={`font-size-18 bx ${i === 0 ? 'bxs-right-arrow-circle bx-fade-right' : 'bx-right-arrow-circle'}`}></i>
                      </div>
                      <div className="flex-shrink-0 d-flex">
                        <div className="me-3">
                          <h5 className="font-size-14">
                            {i === 0 ? (
                              'Live'
                            ) : (
                              <>
                                {moment(log.timestamp).format('DD, MMM. HH:mm:ss')}
                                <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2"></i>
                              </>
                            )}
                          </h5>
                        </div>
                        <div className="flex-grow-1">
                          {i === 0 ? (
                            <MapComponent data={log.location} />
                            <div>{log.address || 'Address not available'}</div>
                          ) : (
                            <div>{log.address || 'Address not available'}</div>
                          )}
                        </div>
                      </div>
                    </li>
                  ))}
                </ul> */}
              </div>
            </div>
        </Modal>
        </Container>
      </div>
    </React.Fragment>
  )
}


export default Dashboard;
