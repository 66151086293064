import React, { useEffect, useState } from 'react';
import { Map, GoogleApiWrapper, Polyline, Marker } from 'google-maps-react';
import PropTypes from 'prop-types';
import { get } from 'helpers/api_helper'
import { Col, Container, Modal, Row, Card, CardBody } from "reactstrap";
import MetaTags from 'react-meta-tags';
import { pathColors } from '../utils/colors'

const MapContainer = ({ google }) => {
    const [pathData, setPathCoordinates] = useState([]);
    const [paths, setPaths] = useState([]);

    const fetchData = async () => {
        try {
            const res = await get('/punch/live');
            if (res.success) {
                setPathCoordinates(res.body);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    
    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if(Object.keys(pathData).length) {
            setPaths(Object.keys(pathData).map((key) => [key, pathData[key]]))
            // setPaths(filteredKeys.map((key) => [key, pathData[key]]));
        }
    }, [pathData])

    if(!Object.keys(pathData).length){
        return 'data';
    }

    const colors = pathColors();
    return (
        <React.Fragment>
            <div className="page-content p-0 m-0">
                <MetaTags>
                    <title>Package management | Field tracking</title>
                </MetaTags>
                <Container fluid className='p-0'>
                    <div style={{
                        height: '720px',
                        width: '272px',
                        right: '82px',
                        top: '98px',
                        zIndex: 9999,
                        background: 'red',
                        position: 'absolute'

                    }}>
                        <Row>
                            <Col>
                                Employee
                            </Col>
                        </Row>
                    </div>
                    <Row>
                        <Col style={{padding: '10px', height: '100vh'}}>
                            <Map
                                google={google}
                                zoom={10}
                                initialCenter={{ lat: 21.1839162, lng: 72.8610854 }} // Initial center of the map
                            >
                                {paths?.map((employee, index) => {
                                    const colorsHexArray = [
                                        "#FF0000", // Red
                                        "#00FF00", // Green
                                        "#0000FF", // Blue
                                        "#00FFFF", // Cyan
                                        "#FF00FF", // Magenta
                                        "#000000", // Black
                                        "#FFFFFF", // White
                                        "#FFA500", // Orange
                                        "#800080", // Purple
                                        "#A52A2A", // Brown
                                        "#FFD700", // Gold
                                        "#FFC0CB", // Pink
                                        "#8A2BE2", // BlueViolet
                                        "#5F9EA0", // CadetBlue
                                        "#D2691E", // Chocolate
                                        "#FF4500", // OrangeRed
                                        "#2E8B57", // SeaGreen
                                        "#6A5ACD", // SlateBlue
                                        "#7FFF00", // Chartreuse
                                        "#DDA0DD", // Plum
                                        "#FF6347", // Tomato
                                        "#20B2AA", // LightSeaGreen
                                        "#FF1493", // DeepPink
                                        "#1E90FF", // DodgerBlue
                                        "#B22222", // FireBrick
                                        "#FFB6C1", // LightPink
                                        "#32CD32", // LimeGreen
                                        "#8B4513", // SaddleBrown
                                        "#4682B4", // SteelBlue
                                        "#FF8C00"  // DarkOrange
                                    ];                
                                    
                                    return <Polyline
                                    key={index}
                                    path={employee[1].paths}
                                    strokeColor={colorsHexArray[index]}
                                    strokeOpacity={0.8}
                                    strokeWeight={2}
                                />
                                })}

                                {paths?.map((employee,  index) => {
                                    const name = employee[1].employee != null ? `${employee[1].employee.firstname} ${employee[1].employee.lastname}` : employee[0];
                                    return <Marker
                                        key={index}
                                        position={{ lat: employee[1].paths[0].lat, lng: employee[1].paths[0].lng }}
                                        icon={{
                                        url: "https://maps.google.com/mapfiles/ms/icons/green-dot.png"
                                        }}
                                        title={`Ride Started - ${name} (distance traveled: ${employee[1].distance})`}
                                    />
                                })}
                                
                                {paths?.map((employee, index) => {
                                    const name = employee[1].employee != null ? `${employee[1].employee.firstname} ${employee[1].employee.lastname}` : employee[0];
                                    return <Marker
                                        key={index}
                                        position={{ lat: employee[1].paths[employee[1].paths.length - 1].lat, lng: employee[1].paths[employee[1].paths.length - 1].lng }}
                                        icon={{
                                        url: "https://maps.google.com/mapfiles/ms/icons/red-dot.png"
                                        }}
                                        title={`Ending Point - ${name} (distance traveled: ${employee[1].distance})`}
                                    />
                                })}
                            </Map>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

MapContainer.propTypes = {
    google: PropTypes.any
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyC4VeWGg1gWcrv2yrFkGWNQlHrFO26AZao',
  libraries: ['places']
})(MapContainer);
