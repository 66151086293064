import PropTypes from 'prop-types';
import "flatpickr/dist/themes/material_red.css"
import Flatpickr from "react-flatpickr"
import Timezone from "utils/timezone";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import moment from 'moment';

const MiniCard = ({data, byDateFilter}) => {
    
    if(!data?.length){
        return (
            <>
                <div className="row">
                    <div className='col-6'></div>
                    <div className='col-6'>
                        <Skeleton width={'100%'} height={45}/>
                    </div>
                </div>
                <br />
                <div className="row mb-3">
                    <div className="col-4">
                        <Skeleton width={'100%'} height={60}/>
                    </div>
                    <div className="col-4">
                        <Skeleton width={'100%'} height={60}/>
                    </div>
                    <div className="col-4">
                        <Skeleton width={'100%'} height={60}/>
                    </div>
                </div>
            </>
        );
    }

    return (
        <>
            <div className="row">
                <div className='col-6'></div>
                <div className='col-6'>
                    <Flatpickr
                        className="form-control d-block"
                        placeholder="DD-MM-YYYY"
                        options={{
                            mode: "single",
                            dateFormat: "d-m-Y"
                        }}
                        defaultValue={moment().format('DD-MM-YYYY')}
                        onChange={
                            (e) => {
                                const dates = e.map(dateString => Timezone(dateString));
                                byDateFilter(dates)
                            }
                        }
                    />
                </div>
            </div>
            <br />
            <div className="row">
                {
                    data?.map( (v, i) => 
                        <div key={`MCL_${i+1}`} className="col-lg-4">
                            <div className="blog-stats-wid card">
                            <div className="card-body">
                                <div className="d-flex flex-wrap">
                                <div className="me-3">
                                    <p className="text-muted mb-2">{v?.title}</p>
                                    <h5 className="mb-0">{v?.value || 0}</h5>
                                </div>
                                <div className="avatar-sm ms-auto">
                                    <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                                    <i className={v?.icon}></i>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    )
                }
            </div>
        </>
    )
}

MiniCard.propTypes = {
    data: PropTypes.any,
    byDateFilter: PropTypes.any
}

export default MiniCard;