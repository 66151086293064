import DataTable from 'common/datatable';
import CustomForm from 'components/form';
import { get, post } from 'helpers/api_helper';
import { PENDING_CASES, GETOPTIONS } from 'helpers/url_helper';
import React, { useEffect, useState } from "react";
import MetaTags from 'react-meta-tags';
import { Col, Container, Modal, Row, Card, CardBody } from "reactstrap";
import LogoWithLoader from 'components/LogoWithLoader ';
import "flatpickr/dist/themes/material_red.css"
import Flatpickr from "react-flatpickr"
import Timezone from "utils/timezone";
import Select from 'react-select';
import moment from 'moment';

const Caserecive = () => {
    const [data, setData] = useState([]);
    const [editData, setEditData] = useState(null);
    const [openForm, setOpenForm] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [formFields, setFormFields] = useState([]);
    const [options, setOptions] = useState([]);
    const [filterAssigners, setFilterAssigners] = useState([]);
    const [filters, setFilters] = useState({
        location:"",
        status:null,
        picked:"",
        accepted:"",
        date:"",
        tracking_no:null,
        case_no:null,
        courier:null,
    })


    // Function to fetch data from the server based on the caseID (optional)
    const fetchData = async (filterObj = null) => {
        try {
            setIsLoading(true);

            let url = `${PENDING_CASES}`;
            if(filterObj){
                const filteredData = Object.fromEntries(
                    Object.entries(filterObj).filter(([_, value]) => value !== null)
                );
                const queryString = new URLSearchParams(filteredData).toString();
                const objstring = btoa(queryString);
                url = `${PENDING_CASES}?${queryString}`;
            }
            const res = await get(url);

            if(!filterObj){
                const options = await get(`${GETOPTIONS}`);
                setOptions(options?.body);
                setFilterAssigners(options?.body?.emp);
            }

            setData(res?.body);
            setIsLoading(false);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    // Fetch initial data when the component mounts
    useEffect(async() => fetchData(), []);
    

    // Function to handle form submission (adding or editing a team)
    const handleSubmit = async (formData) => {
        // Convert form data to an object
        let requestData = {};
        setIsLoading(!isLoading)
        formFields.forEach((v) => {
            requestData = { ...requestData, [v.field.name]: formData[v.field.name].value };
        });

        // If editData exists, add team_id to the requestData for editing
        if (editData) {
            requestData = { ...requestData, team_id: editData.id };
        }

        try {
            // Make an HTTP POST request to add or edit a team
            const res = await post(PICKUP, requestData);

            // If the request is successful, fetch updated data and close the form
            if (res.success) {
                fetchData();
                setIsLoading(false)
                setOpenForm(!openForm);
            }
        } catch (error) {
            console.error("Error submitting form:", error);
        }
    };

    // Function to render action buttons in the table
    // const actions = (cell, row) => {
    //     return (
    //         <div className="d-flex flex-wrap gap-2">
    //             <button type="button" className="btn-label btn btn-success" onClick={() => handleEdit(row.id)}><i className="fas fa-check-double label-icon "></i> Accept</button>
    //         </div>
    //     );
    // }

    const onCallbackDatatable = async () => {
        setOpenForm(!openForm)
    }
    
    const onDrawerCloseCallback = () => {
        setOpenForm(!openForm)
    }

    useEffect(() => {
        const opt = filters.location != undefined ? filterAssigners?.filter(v => v.location == filters.location?.value):options?.emp;
        setFilterAssigners(opt)
    }, [filters])
    
    const handleFilter = () => {
        const finalObj = {
            tracking_no: filters.tracking_no || null,
            case_no: filters.case_no || null,
            courier: filters.courier?.value || null,
            status: filters.status?.value || null,
            location: (filters.location?.value)?.toString() || null,
            added_by: filters.picked?.value || null,
            accepted_by: filters.accepted?.value || null,
            date: filters?.date ? filters?.date?.join(',') : null,
        }
        fetchData(finalObj)
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Pending Case management | Field tracking</title>
                </MetaTags>
                <Container fluid>
                <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col>
                                            <label>Case Number</label>
                                            <input 
                                                className="form-control"
                                                placeholder="filter By case number"
                                                defaultValue={filters?.case_no}
                                                onChange={
                                                    (e) => {
                                                        setFilters(prev => ({...prev, case_no: e.target.value}))
                                                    }
                                                }
                                            />
                                        </Col>
                                        <Col>
                                            <label>Tracking Number</label>
                                            <input 
                                                className="form-control"
                                                placeholder="filter By tracking number"
                                                defaultValue={filters?.tracking_no}
                                                onChange={
                                                    (e) => {
                                                        setFilters(prev => ({...prev, tracking_no: e.target.value}))
                                                    }
                                                }
                                            />
                                        </Col>
                                        <Col>
                                            <label>Picked Date</label>
                                            <Flatpickr
                                                className="form-control d-block bg-white"
                                                placeholder="DD-MM-YYYY"
                                                options={{
                                                    mode: "range",
                                                    dateFormat: "d-m-Y"
                                                }}
                                                defaultValue={filters?.date}
                                                onChange={
                                                    (e) => {
                                                        console.log(e)
                                                        const dates = e.map(dateString => Timezone(dateString));
                                                        setFilters(prev => ({...prev, date: dates}))
                                                    }
                                                }
                                            />
                                        </Col>
                                        <Col>
                                            <label>Case Status</label>
                                            <Select
                                                value={filters?.status}
                                                onChange={(v) => setFilters(prev => ({...prev, status: v}))}
                                                options={[
                                                    {label: "All", value: null},
                                                    {label: "Pending", value: 'pending'},
                                                    {label: "In-transit", value: 'transit'}
                                                ]}
                                            />
                                        </Col>
                                    </Row>
                                    <br />
                                    <Row>
                                        <Col>
                                            <label>Pickup Location</label>
                                            <Select
                                                value={filters?.location}
                                                onChange={(v) => setFilters(prev => ({...prev, location: v}))}
                                                options={options?.location?.map((v, i) => ({label: v.title, value: v.id}))}
                                            />
                                        </Col>
                                        <Col>
                                            <label>Courier</label>
                                            <Select
                                                value={filters?.courier}
                                                onChange={(v) => setFilters(prev => ({...prev, courier: v}))}
                                                options={options?.courier?.map((v, i) => ({label: v.title, value: v.id}))}
                                            />
                                        </Col>
                                        <Col>
                                            <label>Case Picked By</label>
                                            <Select
                                                value={filters?.picked}
                                                onChange={(v) => setFilters(prev => ({...prev, picked: v}))}
                                                options={filterAssigners?.map((v, i) => ({label: v.name, value: v.unique_id}))}
                                            />
                                        </Col>
                                        <Col>
                                            <label>Case Accepted By</label>
                                            <Select
                                                value={filters?.accepted}
                                                onChange={(v) => setFilters(prev => ({...prev, accepted: v}))}
                                                options={options?.emp?.map((v, i) => ({label: v.name, value: v.unique_id}))}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className='text-end'>
                                        <br />
                                            <button className='btn btn-primary' style={{width: '260px'}} onClick={handleFilter}>Filter</button>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    {
                        isLoading ? <LogoWithLoader /> :
                        <DataTable isAdd={false} title="" tableData={data} callback={onCallbackDatatable} />
                    }
                </Container>
            </div>

            {
                openForm && <CustomForm title="New Case's" loading={isLoading} isOpen={openForm} onSubmit={handleSubmit} onDrawerClose={onDrawerCloseCallback} htmlFileds={formFields} />
            }

        </React.Fragment>
    )
}

export default Caserecive;