import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"

import { Card, CardBody, Col, Container, Row } from "reactstrap"

//redux
import { useDispatch, useSelector } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { loginUser } from "../../store/actions"

// import images
import logo from "assets/images/logo.svg"
import adelogo from "assets/images/logo.png"
import profile from "assets/images/profile-img.png"
import { get } from 'helpers/api_helper'
import Toaster from '../Components/toster';

const Login = props => {
  const dispatch = useDispatch()
  const [accessCode, setAccessCode] = useState(null);

  useEffect(async() => {
    const res = await get('organizations/validate');
    if(res?.success){
      setAccessCode(res?.body?.access_code)
    }
  }, [])

  const { error } = useSelector(state => ({
    error: state.Login.error,
  }))

  useEffect(() => {
    if(error != ''){
      Toaster('error', {title: error})
    }
  }, [error]);

  // handleValidSubmit
  const handleValidSubmit = (event, values) => {
    dispatch(loginUser(values, props.history))
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>Login | ADE Tracking</title>
      </MetaTags>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages">
        <Container fluid={true}>
          <Row className="p-0 bg-white" style={{padding: '0px !Important', height: '100vh'}}>
            <Col md={8}>
              <img src={adelogo} height={136} className="p-4"/>
            </Col>
            <Col md={4} className="p-0">
              <Card className="overflow-hidden h-100">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Welcome Back !</h5>
                        <p>Sign in to continue to Dashboard.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/" className="auth-logo-light">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <AvForm
                      className="form-horizontal"
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(e, v)
                      }}
                    >
                      <AvField
                        name="accesscode"
                        type="hidden"
                        value={accessCode?.toString()}
                      />

                      <div className="mb-3">
                        <AvField
                          name="username"
                          label="username"
                          value=""
                          className="form-control"
                          placeholder="Enter username"
                          type="text"
                          required
                        />
                      </div>

                      <div className="mb-3">
                        <AvField
                          name="password"
                          label="Password"
                          value=""
                          type="password"
                          required
                          placeholder="Enter Password"
                        />
                      </div>

                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="customControlInline"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="customControlInline"
                        >
                          Remember me
                        </label>
                      </div>

                      <div className="mt-3 d-grid">
                        <button
                          className="btn btn-primary btn-block"
                          type="submit"
                        >
                          Log In
                        </button>
                      </div>
                      <div className="mt-4 text-center">
                        <Link to="/forgot-password" className="text-muted">
                          <i className="mdi mdi-lock me-1" />
                          Forgot your password?
                        </Link>
                      </div>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
              {/* <div className="mt-5 text-center">
                <p>developed & maintained by Catchysystem Technologies Pvt. Ltd.</p>
              </div> */}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Login)

Login.propTypes = {
  history: PropTypes.object,
}
