import { AvForm } from "availity-reactstrap-validation";
import DataTable from 'common/datatable';
import { get, post } from 'helpers/api_helper';
import { EMPLOYEES, DESIGNATION } from 'helpers/url_helper';
import React, { useEffect, useRef, useState } from "react";
import MetaTags from 'react-meta-tags';
import {
    Container,
    Button,
    Modal
} from "reactstrap";
import Toaster from './Components/toster';
import LogoWithLoader from "components/LogoWithLoader ";

const Employees = () => {
    const resetValue = useRef('');
    const [data, setData] = useState([]);
    const [editData, setEditData] = useState(null);
    const [openForm, setOpenForm] = useState(false);
    const [formFields, setFormFields] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [optionData, setOptionData] = useState(null);

    // Function to fetch data from the server based on the employeeID (optional)
    const fetchData = async (employeeID = false, isDelete = false) => {
        try {
            setIsLoading(true);
            let url = EMPLOYEES;
            if(employeeID) url = `${EMPLOYEES}/${employeeID}`
            if(isDelete) url = `${EMPLOYEES}/delete/${employeeID}`
            
            const res = await get(url);
            
            // If employeeID is provided, set the editData state, otherwise set the data state
            if(employeeID && !isDelete){
                setEditData(res?.body);
            }else{
                setData(res?.body);
            }
            setIsLoading(false);
        } catch (error) {
            console.error("Error fetching data:", error);
        }

    };

    // Fetch initial data when the component mounts
    useEffect(() => { fetchData(); }, []);

    // Function to handle form submission (adding or editing a team)
    const handleSubmit = async (formData) => {
        // Convert form data to an object
        let requestData = {};
        setIsLoading(!isLoading)
        Object.keys(formFields).forEach((v) => {
            let value = formData[v]?.value || ''
            requestData = { ...requestData, [v]: value };
        });
        
        
        try {
            // // Make an HTTP POST request to add or edit a team
            const res = await post(EMPLOYEES, requestData);

            // If the request is successful, fetch updated data and close the form
            if (res.success) {
                setFormFields([])
                setEditData(null)
                fetchData();
                Toaster('', {title: `${requestData?.id ? 'Update': 'Added'} successfully`})
                setIsLoading(false)
                setOpenForm(!openForm);
            }
        } catch (error) {
            console.error("Error submitting form:", error);
        }
    };
    
    // Function to handle the edit action by fetching data for a specific team
    const handleEdit = (id) => {
        onCallbackDatatable()
        fetchData(id)
    };

    // Set default form field values when editData changes
    useEffect(() => {
        if(editData){
            formFields.id = editData?.id;
            formFields.address = editData?.address;
            formFields.alternate_number = editData?.alternate_number;
            formFields.designation = editData?.designation;
            formFields.dob = editData?.dob;
            formFields.doj = editData?.doj;
            formFields.email = editData?.email;
            formFields.firstname = editData?.firstname;
            formFields.gender = editData?.gender;
            formFields.lastname = editData?.lastname;
            formFields.manager = editData?.manager;
            formFields.phone = editData?.phone;
            formFields.role = editData?.role;
            formFields.shift = editData?.shift;
            formFields.team = editData?.team;
            formFields.username = editData?.username;
            formFields.unique_id = editData?.unique_id;
            formFields.base_salary = editData?.base_salary;
            formFields.sales_target = editData?.sales_target;
            formFields.secondary_sales_target = editData?.secondary_sales_target;
            formFields.leaves_count = editData?.leaves_count;
            formFields.location = editData?.location;

            console.log(formFields)

            setOpenForm(!openForm);
        }
    },[editData])
    
    // Dummy function for handling delete action
    const handleDelete = (id) => {
        fetchData(id, true);
        Toaster('', {title: `Employee Deleted Successfully`})
    }
    
    const handleReset = async (data, isType) => {
        const res = await get(`${EMPLOYEES}/reset?_q=${isType ? 'PWD': 'DIV'}&_v=${data.id}&_ui=${data.unique_id}`);
        if(res.success){
            Toaster('', {title: `${data.firstname} ${data.lastname}: ${isType ? 'Password' : 'Device'} Successfully Reset`})
        } else {
            Toaster('error', {title:`${isType ? 'Password' : 'Device'} reset failed. Please try again later.`})
        }
    }


    // Function to render action buttons in the table
    const actions = (cell, row) => {
        return (
            <div className="d-flex flex-wrap gap-2">
                <button type="button" title="unlink device or reset" className="btn btn-cs btn-dark" onClick={() => handleReset(row, false)}><i className="mdi mdi-link-variant-off"></i></button>
                <button type="button" title="password reset" className="btn btn-cs btn-secondary" onClick={() => handleReset(row, true)}><i className="fas  fa-lock-open"></i></button>
                <button type="button" title="edit" className="btn btn-cs btn-warning" onClick={() => handleEdit(row.unique_id)}><i className="bx bx-pencil "></i></button>
                <button type="button" title="delete" className="btn btn-cs btn-danger" onClick={() => handleDelete(row.unique_id)}><i className="fas fa-trash"></i></button>
            </div>
        );
    }

    const onCallbackDatatable = async () => {
        const res = await get(`${EMPLOYEES}/getoptions`);
        if(res.success) {
            setOptionData(res?.body)
            if(!editData?.unique_id){
                setOpenForm(!openForm)
            }
        }
    }

    const onDrawerCloseCallback =  () => {
        setFormFields([])
        setEditData(null)
        setOpenForm(!openForm)
    }

    const updateInputValue = (e) => {
        setFormFields(prev => ({...prev, [e.target.name]: e.target.value}))
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Add Employee | Field tracking</title>
                </MetaTags>
                <Container fluid>
                    {
                        isLoading ? <LogoWithLoader /> :
                    <DataTable isAdd={true} title="Employees" tableData={data} callback={onCallbackDatatable} actions={actions} />
                    }
                </Container>
            </div>

            <Modal
                isOpen={openForm}
                centered={true}
                className="modal-xl"
            >
                <AvForm
                    ref={resetValue}
                    className="form-horizontal"
                    onValidSubmit={(e) => {
                        handleSubmit(e.target)
                    }}
                >
                    <div className="modal-body">
                            <div className="card mb-0">
                                <div className="bg-transparent border-bottom text-uppercase card-header">Login Details
                                    <button
                                        type="button"
                                        onClick={onDrawerCloseCallback}
                                        className="close"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                    >
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="card-body">
                                    <div className="form-group row">
                                        <div className="form-group col-md-4">
                                            <input type="hidden" name="id" defaultValue={formFields?.id} />
                                            <label htmlFor="userName" className="control-label imp">User Name</label>
                                            <input id="userName" name="username" defaultValue={formFields?.username} onChange={updateInputValue} className="form-control" />
                                            <span className="text-danger"></span>
                                        </div>
                                        <div className="form-group col-md-4">
                                            <label htmlFor="phoneNumber" className="control-label imp">Phone Number</label>
                                            <input id="phoneNumber" name="phone" defaultValue={formFields?.phone} onChange={updateInputValue} type="number" className="form-control" />
                                            <span className="text-danger"></span>
                                        </div>
                                        <div className="form-group col-md-4">
                                            <label htmlFor="email" className="control-label imp">Email</label>
                                            <input id="email" name="email" type="email" defaultValue={formFields?.email} onChange={updateInputValue} className="form-control" />
                                            <span className="text-danger"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card mb-0">
                                <div className="bg-transparent border-bottom text-uppercase card-header">Personal Details</div>
                                <div className="card-body">
                                    <div className="form-group row">
                                        <div className="form-group col-md-3 mb-3">
                                            <label htmlFor="firstName" className="control-label imp">First Name</label>
                                            <input id="firstName" name="firstname" defaultValue={formFields?.firstname} onChange={updateInputValue} className="form-control" />
                                            <span className="text-danger"></span>
                                        </div>
                                        <div className="form-group col-md-3 mb-3">
                                            <label htmlFor="lastName" className="control-label imp">Last Name</label>
                                            <input id="lastName" name="lastname" defaultValue={formFields?.lastname} onChange={updateInputValue} className="form-control" />
                                            <span className="text-danger"></span>
                                        </div>
                                        <div className="form-group col-md-3 mb-3">
                                            <label htmlFor="gender" className="control-label imp">Gender</label>
                                            <select id="gender" name="gender" defaultValue={formFields?.gender} onChange={updateInputValue} className="form-select mb-3">
                                                <option value="male">Male</option>
                                                <option value="female">Female</option>
                                                <option value="other">Other</option>
                                            </select>
                                            <span className="text-danger"></span>
                                        </div>
                                        <div className="form-group col-md-3 mb-3">
                                            <label htmlFor="dob" className="control-label">Date of birth</label>
                                            <input id="dob" name="dob" type="date" value={formFields?.dob} onChange={updateInputValue} className="form-control" />
                                            <span className="text-danger"></span>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="form-group col-md-3 mb-3">
                                            <label htmlFor="uniqueId" className="control-label imp">Unique Id {editData?.unique_id}</label>
                                            <input id="uniqueId" name="unique_id" defaultValue={formFields?.unique_id} readOnly={formFields?.id && formFields?.unique_id} onChange={updateInputValue} className="form-control" />
                                            <span className="text-danger"></span>
                                        </div>
                                        <div className="form-group col-md-3 mb-3">
                                            <label htmlFor="alternateNumber" className="control-label">Alternate Number</label>
                                            <input id="alternateNumber" name="alternate_number" value={formFields?.alternate_number} onChange={updateInputValue} type="number" className="form-control" />
                                            <span className="text-danger"></span>
                                        </div>
                                        <div className="form-group col-md-6 mb-3">
                                            <label htmlFor="address" className="control-label imp">Address</label>
                                            <input id="address" name="address" value={formFields?.address} onChange={updateInputValue} className="form-control" />
                                            <span className="text-danger"></span>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="card mb-0">
                                <div className="bg-transparent border-bottom text-uppercase card-header">Work Details</div>
                                <div className="card-body">
                                    <div className="form-group row">
                                        <div className="form-group col-md-3 mb-3">
                                            <label id="manger" className="control-label imp">Manager</label>
                                            <select id="manger" name="manager" value={formFields?.manager || ''} onChange={updateInputValue} className="form-select mb-3">
                                                <option>Please select a manager</option>
                                                {optionData?.manegers?.map((v, i) => <option key={i} value={v.unique_id}>{`${v.firstname} ${v.lastname}`}</option>)}
                                            </select>
                                            <span className="text-danger"></span>
                                        </div>
                                        <div className="form-group col-md-3 mb-3">
                                            <label id="shiftId" className="control-label imp">Shift</label>
                                            <select id="shiftId" name="shift" value={formFields?.shift || ''} onChange={updateInputValue} className="form-select mb-3">
                                                <option>Please select a shift</option>
                                                {optionData?.shifts?.map((v, i) => <option key={i} value={v.id}>{v.title}</option>)}
                                            </select>
                                            <span className="text-danger"></span>
                                        </div>
                                        <div className="form-group col-md-3 mb-3">
                                            <label htmlFor="locationId" className="control-label imp">Location</label>
                                            <select id="locationId" name="location" value={formFields?.location || ''} onChange={updateInputValue} className="form-select mb-3">
                                                <option>Please select a location</option>
                                                {optionData?.locations?.map((v, i) => <option key={i} value={v.id}>{v.title}</option>)}
                                            </select>
                                            <span className="text-danger"></span>
                                        </div>
                                        <div className="form-group col-md-3 mb-3">
                                            <label htmlFor="teamId" className="control-label imp">Team</label>
                                            <select id="teamId" name="team" value={formFields?.team || ''} onChange={updateInputValue} className="form-select mb-3">
                                                <option>Please select a team</option>
                                                {optionData?.teams?.map((v, i) => <option key={i} value={v.id}>{v.title}</option>)}
                                            </select>
                                            <span className="text-danger"></span>
                                        </div>
                                        <div className="form-group col-md-3 mb-3">
                                            <label htmlFor="dateOfJoin" className="control-label">Date Of Join</label>
                                            <input id="dateOfJoin" name="doj" value={formFields?.doj} type="date" className="form-control" />
                                            <span className="text-danger"></span>
                                        </div>
                                    
                                        <div className="form-group col-md-3 mb-3">
                                            <label htmlFor="designation" value={formFields?.designation || ''} className="control-label imp">Designation</label>                                            
                                            <select id="designation" name="designation" value={formFields?.designation || ''} onChange={updateInputValue} className="form-select mb-3">
                                                <option>Please select a designation</option>
                                                {optionData?.designations?.map((v, i) => <option key={i} value={v.id}>{v.title}</option>)}
                                            </select>

                                            <span className="text-danger"></span>
                                        </div>
                                        <div className="form-group col-md-3 mb-3">
                                            <label htmlFor="baseSalary" defaultValue={formFields?.designation} className="control-label">Base Salary</label>
                                            <input id="baseSalary" name="base_salary" defaultValue={formFields?.base_salary} type="number" className="form-control" />
                                            <span className="text-danger"></span>
                                        </div>
                                        <div className="form-group col-md-3 mb-3">
                                            <label htmlFor="primarySalesTarget" className="control-label">Primary Sales Target</label>
                                            <input id="primarySalesTarget" name="sales_target" defaultValue={formFields?.sales_target} type="number" className="form-control" />
                                            <span className="text-danger"></span>
                                        </div>
                                        <div className="form-group col-md-3 mb-3">
                                            <label htmlFor="secondarySalesTarget" className="control-label">Secondary Sales Target</label>
                                            <input id="secondarySalesTarget" name="secondary_sales_target" defaultValue={formFields?.secondary_sales_target} type="number" className="form-control" />
                                            <span className="text-danger"></span>
                                        </div>
                                        <div className="form-group col-md-3 mb-3">
                                            <label htmlFor="availableLeaveCount" className="control-label">Available Leave Count</label>
                                            <input id="availableLeaveCount" name="leaves_count" defaultValue={formFields?.leaves_count} type="number" className="form-control" />
                                            <span className="text-danger"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </div>
                    <div className="modal-footer">
                        <div className="col-md-2 col-offset-md-10" key={`htmlFiled_button`}>
                            <Button color="primary w-100" type="submit" disabled={isLoading}>{isLoading ? <i className="fas fa-spinner fa-spin"></i>: 'Submit'}</Button>
                        </div>
                    </div>
                </AvForm>
            </Modal>
        </React.Fragment>
    )
}

export default Employees;