import DataTable from 'common/datatable';
import { get, post } from 'helpers/api_helper';
import { CASE, GETOPTIONS } from 'helpers/url_helper';
import React, { useEffect, useState } from "react";
import MetaTags from 'react-meta-tags';
import { Col, Container, Modal, Row, Card, CardBody } from "reactstrap";
import LogoWithLoader from 'components/LogoWithLoader ';
import moment from 'moment';
import Select from 'react-select';
import "flatpickr/dist/themes/material_red.css"
import Flatpickr from "react-flatpickr";
import Timezone from "utils/timezone";
import { encryptMessage } from '../utils/encryptionHelper'

const initObj = {
    location: "",
    employee_id: "",
    remark: "",
}

const RequestPickup = () => {
    const [data, setData] = useState([]);
    const [options, setOptions] = useState([]);
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState(initObj)
    const [employeeOptions, setEmployeeOptions] = useState(null)
    const [filterAssigners, setFilterAssigners] = useState([])
    const [isViewModal, setIsViewModal] = useState(false)
    const [isViewModalData, setIsViewModalData] = useState(null)
    const [filters, setFilters] = useState({
        location:"",
        added:"",
        assigned:"",
        date:""
    })

    const fetchData = async (data = null) => {
        try {
            setIsLoading(true)
            
            let url = `${CASE}/request/pickup`;
            if(data){
                const filteredData = Object.fromEntries(
                    Object.entries(data).filter(([_, value]) => value !== null)
                );
                const queryString = new URLSearchParams(filteredData).toString();
                const objstring = btoa(queryString);
                url = `${CASE}/request/pickup?${queryString}`;
            }
            if(!data){
                const options = await get(`${GETOPTIONS}`);
                setOptions(options?.body);
                setFilterAssigners(options?.body?.emp);
            }
            const res = await get(url);

            setData(res?.body);
            setIsLoading(false)
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    // Fetch initial data when the component mounts
    useEffect(async() => fetchData(), []);

    // Function to render action buttons in the table
    const actions = (cell, row) => {  
        return (
            row.assigned != '---' ?
            <div className="d-flex flex-wrap">
                <button title="All Cases" type="button" className="btn btn-success" onClick={() => handleViewRequestData(row)}><i className="fas fa-eye"></i></button>
            </div> : ''
        );
    }

    const handleAddRequest = async() => {
        setIsLoading(!isLoading)
        const res = await post(`${CASE}/request/pickup`, formData);
        if(res.success){
            setFormData(initObj);
            fetchData();
            setIsLoading(false)
            setIsOpenModal(false);
        }
    }

    const filterEmployeesBasedOnLocation = (location) => {
        setEmployeeOptions(options?.emp?.filter(emp => emp.location == location))
    }

    useEffect(() => {
        const opt = filters.location != undefined ? filterAssigners?.filter(v => v.location == filters.location?.value):options?.emp;
        setFilterAssigners(opt)
    }, [filters])

    
    const handleFilter = () => {
        const finalObj = {
            location: (filters.location?.value)?.toString() || null,
            employee_id: filters.assigned?.value || null,
            added_by: filters.added?.value || null,
            date: filters?.date ? filters?.date?.join(',') : null,
        }
        fetchData(finalObj)
    }

    const handleViewRequestData = async(data) => {
        const res = await get(`${CASE}/request/pickup/case?_q=${data.id}`);
        if(res.success){
            setIsViewModalData({...data, case: res?.body});
            setIsViewModal(true)
        }
    }
    console.log(isViewModalData)

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Request Pickups | Field tracking</title>
                </MetaTags>
                <Container fluid>
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col>
                                            <label>Date</label>
                                            <Flatpickr
                                                className="form-control d-block bg-white"
                                                placeholder="DD-MM-YYYY"
                                                options={{
                                                    mode: "range",
                                                    // mode: "multiple",
                                                    dateFormat: "d-m-Y"
                                                }}
                                                defaultValue={filters?.date}
                                                onChange={
                                                    (e) => {
                                                        const dates = e.map(dateString => Timezone(dateString));
                                                        setFilters(prev => ({...prev, date: dates}))
                                                    }
                                                }
                                            />
                                        </Col>
                                        <Col>
                                            <label>Location</label>
                                            <Select
                                                value={filters?.location}
                                                isClearable
                                                onChange={(v) => setFilters(prev => ({...prev, location: v}))}
                                                options={options?.location?.map((v, i) => ({label: v.title, value: v.id}))}
                                            />
                                        </Col>
                                        <Col>
                                            <label>Added By</label>
                                            <Select
                                                value={filters?.added}
                                                isClearable
                                                onChange={(v) => setFilters(prev => ({...prev, added: v}))}
                                                options={options?.emp?.map((v, i) => ({label: v.name, value: v.unique_id}))}
                                            />
                                        </Col>
                                        <Col>
                                            <label>Assigned To</label>
                                            <Select
                                                value={filters?.assigned}
                                                isClearable
                                                onChange={(v) => setFilters(prev => ({...prev, assigned: v}))}
                                                options={filterAssigners?.map((v, i) => ({label: v.name, value: v.unique_id}))}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className='text-end'>
                                            <br />
                                            <button className='btn btn-primary' style={{width: '260px'}} onClick={handleFilter}>Filter</button>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    {
                         isLoading ? <LogoWithLoader /> :
                        <DataTable isAdd={true} title="Pickup Request" tableData={data} callback={() => setIsOpenModal(true)} actions={actions}/>
                    }
                </Container>
            </div>

            {
                isOpenModal &&
                <Modal size="lg" isOpen={isOpenModal} >
                    <div className="modal-header">
                    <h5 className="modal-title mt-0" id="exampleModalFullscreenLabel"> Add Pickup Request </h5>
                    <button onClick={() => setIsOpenModal(false)} type="button" className="close" data-dismiss="modal" aria-label="Close" ><span aria-hidden="true">&times;</span></button>
                    </div>
                    <div className="modal-body">
                        <Row>
                            <Col md={12} className='mb-2'>
                                <label>Location</label>
                                <select className='form-control' onChange={(e) => {
                                    filterEmployeesBasedOnLocation(e.target.value);
                                    setFormData(prev => ({...prev, location: e.target.value}))
                                }}>
                                    <option value="" key={'LOC_OP_0'}>---</option>
                                    { options?.location?.length > 0 && options?.location?.map((v, i) => <option key={`LOE_OP_${i+1}`} value={v.id}>{v.title}</option>)}
                                </select>
                            </Col>
                            <Col md={12} className='mb-2'>
                                <label>Assign To</label>
                                <select className='form-control' onChange={(e) => setFormData(prev => ({...prev, employee_id: e.target.value}))}>
                                    <option value="" key={'LOC_OP_0'}>---</option>
                                    { employeeOptions && employeeOptions?.map((v, i) => <option key={`LOE_OP_${i+1}`} value={v.unique_id}>{v.name}</option>)}
                                </select>
                            </Col>
                            <Col md={12} className='mb-2'>
                                <label>Remark</label>
                                <textarea className='form-control'  onChange={(e) => setFormData(prev => ({...prev, remark: e.target.value}))} defaultValue={formData?.remark} />
                            </Col>
                        </Row>
                    </div>
                    <div className="modal-footer">
                        <button type="button" onClick={() => setIsOpenModal(!isOpenModal)} className="btn btn-secondary" data-dismiss="modal" > Close </button>
                        <button type="button" className="btn btn-primary" disabled={isLoading} onClick={() => handleAddRequest()}>{isLoading? <i className='fas -fa-spinner fa-spin'></i>: 'Submit'} </button>
                    </div>
                </Modal>
            }

            {
                isViewModal &&
                <Modal size="lg" isOpen={isViewModal} >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0" id="exampleModalFullscreenLabel"> View Pickup Request Status </h5>
                        <button onClick={() => setIsViewModal(false)} type="button" className="close" data-dismiss="modal" aria-label="Close" ><span aria-hidden="true">&times;</span></button>
                    </div>
                    <div className="modal-body">
                        <h5>Date: <b>{moment(isViewModalData?.created_at).format('D MMM, YYYY HH:mm A')}</b></h5>
                        <h5>Generated By: <b>{isViewModalData?.added}</b></h5>
                        <h5>Location: <b>{isViewModalData?.location}</b></h5>

                        {
                            console.log(isViewModalData)
                        }
                        
                        <table className='table table-bordered'>
                            <tbody>
                                <tr><td>Assigned to</td><td>{isViewModalData?.assigned}</td></tr>
                                <tr><td>Accepted datetime</td><td>{isViewModalData?.accepted_at}</td></tr>
                                <tr><td colSpan={2} style={{textAlign:'center'}}><b>PickUp Details</b></td></tr>
                                <tr><td>Case No</td><td>{isViewModalData?.case?.case_no}</td></tr>
                                <tr><td>Location</td><td>{isViewModalData?.case?.Location?.title}</td></tr>
                                <tr><td>Pickup by</td><td>{isViewModalData?.case?.Accepted ? `${isViewModalData?.case?.Accepted?.firstname} ${isViewModalData?.case?.Accepted?.lastname} (${isViewModalData?.case?.Accepted?.unique_id})`: '---'}</td></tr>
                                
                                <tr><td>Date</td><td>{isViewModalData?.case?.accepted_datetime && moment(isViewModalData?.case?.accepted_datetime).format('D MMM, YYYY HH:mm A')}</td></tr>
                                <tr><td>Remark</td><td>{isViewModalData?.case?.remark}</td></tr>
                                <tr><td colSpan={2} style={{textAlign:'center'}}> ----- </td></tr>
                                <tr><td>In transit</td><td>{isViewModalData?.case?.tracking_id > 0 ? 'Yes' : 'Pending'}</td></tr>
                                <tr><td>Courier</td><td>{isViewModalData?.case?.Tracking?.Courier?.title}</td></tr>
                                <tr><td>Tracking ID</td><td>{isViewModalData?.case?.Tracking?._number}</td></tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="modal-footer">
                        <button type="button" onClick={() => setIsViewModal(!isViewModal)} className="btn btn-secondary" data-dismiss="modal" > Close </button>
                    </div>
                </Modal>
            }
        </React.Fragment>
    )
}

export default RequestPickup;