// Function to generate 200 unique dark colors
const generateDarkColors = (numColors) => {
    let colors = [];
    for (let i = 0; i < numColors; i++) {
        // Generate H, S, B (Hue, Saturation, Brightness)
        let hue = Math.floor(i * (360 / numColors)); // Spread hue evenly
        let saturation = 70 + Math.random() * 30; // Saturation between 70-100%
        let brightness = 10 + Math.random() * 20; // Brightness between 10-30%
        colors.push(hsvToHex(hue, saturation, brightness));
    }
    return colors;
};

// Helper function to convert HSV to RGB and then to Hex
const hsvToHex = (h, s, v) => {
    s /= 100;
    v /= 100;
    let c = v * s;
    let x = c * (1 - Math.abs((h / 60) % 2 - 1));
    let m = v - c;
    let r = 0, g = 0, b = 0;
    if (0 <= h && h < 60) { r = c; g = x; b = 0; }
    else if (60 <= h && h < 120) { r = x; g = c; b = 0; }
    else if (120 <= h && h < 180) { r = 0; g = c; b = x; }
    else if (180 <= h && h < 240) { r = 0; g = x; b = c; }
    else if (240 <= h && h < 300) { r = x; g = 0; b = c; }
    else if (300 <= h && h < 360) { r = c; g = 0; b = x; }
    r = Math.round((r + m) * 255);
    g = Math.round((g + m) * 255);
    b = Math.round((b + m) * 255);
    return rgbToHex(r, g, b);
};

// Helper function to convert RGB to Hex
const rgbToHex = (r, g, b) => {
    return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase();
};

// Function to generate the opposite color
const getOppositeColor = (color) => {
    // Convert hex to RGB
    let r = parseInt(color.slice(1, 3), 16);
    let g = parseInt(color.slice(3, 5), 16);
    let b = parseInt(color.slice(5, 7), 16);
    
    // Convert RGB to HSV
    let max = Math.max(r, g, b);
    let min = Math.min(r, g, b);
    let h, s, v = max / 255;

    if (max === min) {
        h = 0; // achromatic
    } else {
        let d = max - min;
        s = v > 0 ? d / max : 0;
        switch (max) {
            case r: h = (g - b) / d + (g < b ? 6 : 0); break;
            case g: h = (b - r) / d + 2; break;
            case b: h = (r - g) / d + 4; break;
        }
        h *= 60; // Convert to degrees
    }

    // Calculate the complementary color
    let oppositeHue = (h + 180) % 360;
    
    // Convert back to hex
    return hsvToHex(oppositeHue, s * 100, v * 100);
};

// Function to generate 200 unique dark colors and their opposite colors
export const pathColors = () => {
    const darkColors = generateDarkColors(20);
    const oppositeColors = darkColors.map(getOppositeColor);
    return { darkColors, oppositeColors };
};
