import Breadcrumb from "components/Common/Breadcrumb";
import React, { useEffect, useRef, useState } from "react";
import MetaTags from 'react-meta-tags';
import {
    Button,
    Container,
    Card,
    CardBody,
    Row,
    Col,
    CardHeader,
    Modal
} from "reactstrap";
import moment from "moment";
import { ATTENDANCE } from 'helpers/url_helper'
import { post } from 'helpers/api_helper'


const months = ["January", "February", "March", "April", "May", "June",  "July", "August", "September", "October", "November", "December" ];
const startYear = 2023;

const Attendance = () => {
    const [currentMonth, setCurrentMonth] = useState(moment().month());
    const [currentMonthDays, setCurrentMonthDays] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [_data, setData] = useState(null);
    const [logModalOpen, setLogModalOpen] = useState(false);
    const [currentYear, setCurrentYear] = useState(moment().year());
    

    const getData = async(days) => {
        setData(null)
        const res = await post(ATTENDANCE, {month: parseInt(currentMonth), year: currentYear});
        if(res.success){
            const tempData = res.body; 
            setData(tempData?.map((v, i) => (
                <tr key={`MALD_${i}`}>
                    <td>{i + 1}</td>
                    <td onClick={() => showLogs(v.unique_id)} >{v.name}</td>
                    {v.logs ? (
                        Object.keys(v.logs).map((s, si) => {
                            return (v.logs[s] ? (<td key={`DLS_${i}_${si}`} className={`bg-success`} ><b className="text-white">P</b></td>) : (<td key={`DLS_${i}_${si}`}><span className="text-danger">A</span></td>))
                        })
                    ) : (
                        days?.map((day, idx) => (
                            <td key={`DLS_${i}_${idx}`} >
                                <span className="text-danger">A</span>
                            </td>
                        ))
                    )}
                </tr>
            )))
            setIsLoading(false);
        }
    }
    
    useEffect(() => {
        const month = moment().month(currentMonth);
        const days = Array.from({ length: month.daysInMonth() }, (_, i) => i + 1)
        setCurrentMonthDays(days)
        setIsLoading(true);
        getData(days);
    }, [currentMonth])

    const resetFilters = () => {
        alert('asd');
    }

    const showLogs = (empID) => {
        setLogModalOpen(true)
    }

    const generateYearOptions = () => {
        let options = [];
        for (let year = currentYear; year >= startYear; year--) {
            options.push(
                <option key={year} value={year}>
                    {year}
                </option>
            );
        }
        return options;
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Attendace | Field tracking</title>
                </MetaTags>
                <Container fluid>
                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardBody className="border-bottom">
                                    <h5 className="mb-0">Attandence</h5>
                                    <button style={{right: '12px', top: '12px'}} onClick={() => resetFilters()} className="position-absolute btn btn-light me-1" ><i className="mdi mdi-refresh"></i></button>
                                </CardBody>
                                
                                <CardBody>
                                    <Row className="mb-2">
                                        <Col>
                                            {<div style={{display: 'flex'}}> 
                                                <select className="form-control ms-2 me-2" value={currentMonth} style={{width: '160px'}} onChange={e => setCurrentMonth(e.target.value)}>
                                                    {months.map((month, index) => (
                                                        <option key={index + 1} value={index}>{month}</option>
                                                    ))}
                                                </select>
                                                <select className="form-control ms-2 me-2" value={currentYear} style={{width: '160px'}} onChange={e => setCurrentYear(e.target.value)}>
                                                   {generateYearOptions()}
                                                </select>
                                            <h4 className="mt-2">Attendance</h4></div>}
                                        </Col>
                                    </Row>
                                    <Row style={{height: '60vh', overflowY: 'auto'}}>
                                        <Col>
                                            <table className="table table-borderd">
                                                <thead className="bg-light sticky-top">
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Employee</th>
                                                        {
                                                            currentMonthDays.length > 1 && currentMonthDays.map( v => <th key={v}>{v}</th>)
                                                        }
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {!isLoading ? _data : 'loading'}
                                                </tbody>
                                            </table>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>            

            <Modal size="sm" isOpen={logModalOpen} className="modal-lg" >
                <div className="modal-header">
                    <span>Activity</span>
                    <button type="button" className="close" onClick={() => setLogModalOpen(false)}><span aria-hidden="true">×</span></button>
                </div>
                <div className="modal-body">
                    <ul className="verti-timeline list-unstyled">
                        <li className="event-list false">
                            <div className="event-timeline-dot">
                                <i className="font-size-18 bx bx-right-arrow-circle"></i>
                            </div>
                            <div className="flex-shrink-0 d-flex">
                                <div className="me-3">
                                    <h5 className="font-size-14">
                                        22 Nov
                                        <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2"></i>
                                    </h5>
                                </div>
                                <div className="flex-grow-1">
                                    <div>Responded to need “Volunteer Activities”</div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </Modal>

        </React.Fragment>
    )
}

export default Attendance;