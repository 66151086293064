import axios from "axios";


//pass new generated access token here
let token = localStorage?.getItem('accesstoken')

const axiosApi = axios.create({
  baseURL:  process.env.REACT_APP_APIURL || "http://127.0.0.1:4001/v1/"
  // baseURL: "http://127.0.0.1:4001/api/v1/"
})
if (token) {
  axiosApi.defaults.headers.common["Authorization"] = token;
}

let isRefreshing = false;

axiosApi.interceptors.response.use(
  response => response,
  async error => {
    const originalRequest = error.config;
    const isTokenExpired = error.response && (error.response.status === 401 || error.response.status === 403);

    if (isTokenExpired && !originalRequest._retry) {
      if(error.config?.url == "validation/getaccesstoken"){
        window.location.href = '/logout'; // Redirect if no refresh token is available
        return false;
      }
      
        if (isRefreshing) {
        // Wait for the token refresh to complete
        await new Promise(resolve => setTimeout(resolve, 10000));
        return refreshTokenAndRetry(originalRequest);
      } else {
        isRefreshing = true;  // Start token refreshing
        return refreshTokenAndRetry(originalRequest);
      }
    }

    // For other errors, reject the promise
    return Promise.reject(error);
  }
);

async function refreshTokenAndRetry(originalRequest) {
  try {
    const newToken = await refreshToken();
    
    if (!newToken) {
      // If refresh token fails, redirect to login
      window.location.href = '/login'; // Redirect to login page
      return Promise.reject(new Error('Refresh token failed, redirecting to login')); // Reject the promise
    }

    originalRequest._retry = true;
    axiosApi.defaults.headers.common['Authorization'] = newToken;
    originalRequest.headers.Authorization = newToken;
    return axiosApi(originalRequest); // Retry the original request with the new token
  } catch (refreshError) {
    return Promise.reject(refreshError);
  } finally {
    isRefreshing = false; // Reset the refreshing flag
  }
}

async function refreshToken() {
  try {
    const refreshToken = localStorage.getItem('refreshToken');
    if (!refreshToken) {
      window.location.href = '/logout'; // Redirect if no refresh token is available
      return false;
    }

    const response = await post('validation/getaccesstoken', { "refreshtoken": refreshToken });
    const isTokenExpired = response && (response.status === 401 || response.status === 403);

    if (!isTokenExpired && response?.success) {
      const localToken = response.body;
      localStorage.setItem("accesstoken", localToken.accessToken);
      localStorage.setItem('refreshToken', localToken.refreshToken);
      axiosApi.defaults.headers.common['Authorization'] = localToken.accessToken;
      return localToken.accessToken; // Return the new access token
    }
  } catch (error) {
    window.location.href = '/logout'; // Redirect on error
    return false;
  }
}


export async function cdnpost(data, setUploadProgress) {
  return axiosApi
    .post('https://cdn.keepsmiles.in/upload.php', data, { headers: {
      'Content-Type': 'multipart/form-data'
    },
    onUploadProgress: (progressEvent) => {
      const progress = (progressEvent.loaded / progressEvent.total) * 100;
      setUploadProgress(progress);
    }})
    .then(response => response.data)
}

export async function get(url, config = {}) {
  return await axiosApi.get(url, { ...config }).then(response => response.data)
}

export async function post(url, data, config = {}) {
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data)
}
