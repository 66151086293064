import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import L from 'leaflet'; // Make sure to install leaflet
import 'leaflet/dist/leaflet.css'; // Import Leaflet CSS

const MapComponent = ({ data }) => {
    useEffect(() => {
        // Default coordinates
        const defaultCoords = [11.016844, 76.955832];

        // Initialize the map
        const map = L.map('single_map').setView(defaultCoords, 6);

        // Set up the tile layer
        L.tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        maxZoom: 16,
        }).addTo(map);

        // Create an array to hold latlng values
        const latlngs = [];

        // Loop through the data to create polylines
        for (let i = 0; i < data.length; i++) {
            const loc = data[i].location.split(',').map(Number);
            latlngs.push([loc[0], loc[1]]);
        }

        // Create the polyline and add it to the map
        const polyline = L.polyline(latlngs, { color: 'red' }).addTo(map);

        // Fit the map bounds to the polyline
        map.fitBounds(polyline.getBounds());

        // Cleanup function to remove the map instance on component unmount
        return () => {
            map.remove();
        };
    }, [data]); // Run the effect whenever the data changes

    return <div id="single_map" style={{ height: '500px', width: '100%' }}></div>;
};


MapComponent.propTypes = {
    data: PropTypes.any,
};

export default MapComponent;
