import { initializeApp } from 'firebase/app';
import { getDatabase, onValue, ref } from 'firebase/database';
import GoogleMapReact from 'google-map-react';
// import { addDoc, collection, getFirestore } from 'firebase/firestore';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { firebaseConfig } from 'constants/google-service';
import logo from 'assets/images/logo_loader.png'

const center = {
  lat: 20.5937, // default latitude
  lng: 78.9629, // default longitude
};

const mainDivStyle = {
    zIndex: '9999',
    width: '230px',
    position: 'absolute',
    top: '36px', left: '-80px',
    backgroundColor: 'white',
    padding: '10px',
    borderRadius: '5px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)'
}

const toggleStyle = {
    cursor: 'pointer',
    fontSize:'22px',
    position:'absolute',
    top: '10px',
    right: '8px'
}

const Marker = ({ location }) => {
    const [showCard, setShowCard] = useState(false);
    const [address, setAddress] = useState('');

    const toggleCard = () => {
        setShowCard(!showCard);
    };

    const showBettry = (percentage) => {
        let batteryIcon;

        switch (true) {
            case percentage === 0:
                batteryIcon = 'fas fa-battery-empty text-danger';
                break;
            case percentage < 25:
                batteryIcon = 'fas fa-battery-quarter text-warning';
                break;
            case percentage < 75:
                batteryIcon = 'fas fa-battery-half text-warning';
                break;
            case percentage < 100:
                batteryIcon = 'fas fa-battery-three-quarters text-success';
                break;
            default:
                batteryIcon = 'fas fa-battery-full text-success';
        }

        return <span className={`${batteryIcon} ms-2`}><span className='ms-1' style={{position:'relative', fontSize: '18px', top: '-2px'}}>{percentage}<small style={{fontSize: '12px'}}>%</small></span></span>;
    }
    
    const getAddress = () => {
        const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${location.lat},${location.lng}&key=AIzaSyBLeOI-YlGX2omhOYgRhNLf6zp3yI9RKFw`;

        fetch(url)
        .then(response => response.json())
        .then(data => {
            const firstResult = data?.results[0];
            if (firstResult) {
                setAddress(firstResult.formatted_address);
            } else {
                setAddress('Address not found.!');
            }
        })
        .catch(error => {
            setAddress('Error fetching data');
            console.error('Error fetching data:', error);
        });
    }

    useEffect(() => {
        getAddress();
    }, [showCard]);

    return (
        <div>
            <div
                style={{
                position: 'relative',
                width: '32px',
                zIndex: '99',
                height: '32px',
                cursor: 'pointer',
                border: showCard ? '2px solid #878bbb' : 'none',
                borderRadius: showCard ? '50%' : 'none', // Apply border if showCard is true
                }}
                onClick={toggleCard}
            >
                {
                    <img
                        src={logo}
                        // src={`https://randomuser.me/api/portraits/men/${location.showBettry}.jpg`}
                        alt="Profile"
                        style={{ width: '100%', height: '100%', borderRadius: '50%', background: 'black' }}
                    />
                }
            </div>

            {showCard && (
            <div style={mainDivStyle}>
                <div>
                    <div style={{fontSize:'22px'}}>
                        <i style={{color: 'green', fontSize: '14px', position: 'relative', top: '-3px', left: '4px'}} className={`fas ${location?.netwoktype == 'mobile'? 'fa-signal' : 'fa-wifi'}`}></i>
                        {" "} 
                        {showBettry(location?.showBettry)}
                    </div>
                    <div style={toggleStyle}><i className='fas fa-window-close' onClick={toggleCard}></i></div>
                </div>
                <h3>{location.name}</h3>
                <p>Address: {address}</p>
                <span>Brand: {location?.brand?.toUpperCase()} ({location?.modal?.toUpperCase()}) </span>
            </div>
            )}
        </div>
    );
};


Marker.propTypes = {
    location: PropTypes.any,
};

// // Initialize Firebase
const app = initializeApp(firebaseConfig);
const database = getDatabase(app);
const locations = null

const LiveViewMap = ({devices}) => {
    const [deviceLocations, setDeviceLocations] = useState(null)

    const insertDataIntoFirestore = async (devices) => {
        try {
            const dataRef = ref(database);
            const unsubscribe = onValue(dataRef, (snapshot) => {
                const data = snapshot.val();
                
                if(data){
                    const mergedData = devices?.filter(v => data[v.employee_id] != undefined ).map(dl => {
                        if(data[dl.employee_id]){
                            const ObjKeys = Object.keys(data[dl.employee_id])
                            const lastKey = ObjKeys[ObjKeys.length - 2];
                            const finalData = data[dl.employee_id][lastKey];

                            const location = finalData?.location?.split(/[,:]/);

                            if(location?.length === 2){
                                return { 
                                    modal: dl.model_name,
                                    brand: dl.brand,
                                    showBettry: finalData?.battery,
                                    netwoktype: finalData?.wifi_info || 'mobile',
                                    name: dl.employee,
                                    lat: location[0],
                                    lng: location[1],
                                    profile: 12
                                }
                            } else {
                                return { 
                                    modal: dl.model_name,
                                    brand: dl.brand,
                                    showBettry: finalData?.battery,
                                    netwoktype: finalData?.wifi_info || 'mobile',
                                    name: dl.employee,
                                    lat: null,
                                    lng: null,
                                    profile: 12
                                }
                            }
                        } 
                        return dl; // Return dl unchanged if no match is found
                    });
                    
                    setDeviceLocations(mergedData);
                } else {
                    setDeviceLocations(null);
                }
            });
    
            // Return the unsubscribe function to clean up listener
            return () => {
                unsubscribe();
            };
        } catch (error) {
            console.error("Error reading documents: ", error);
        }
    };
    
    useEffect(() => {
        if(devices?.length){
            insertDataIntoFirestore(devices);
        }
    }, [devices])

    if(!insertDataIntoFirestore){
        return 'loading';
    }

    return(
        <div className="card">
            <div className="card-body" style={{height: '100vh', paddingBottom: '48px'}}>
            <div className="h4 card-title">Employee Live Location</div>
                <GoogleMapReact
                    bootstrapURLKeys={{ key: "AIzaSyC4VeWGg1gWcrv2yrFkGWNQlHrFO26AZao"}}
                    defaultZoom={5}
                    defaultCenter={center}
                >
                    {deviceLocations && deviceLocations?.map((location, i) => {
                        return <Marker key={`LOC_${i}`} lat={location?.lat} lng={location?.lng} location={location} />
                    })}
                </GoogleMapReact>
            </div>
        </div>
    )
}

LiveViewMap.propTypes = {
    devices: PropTypes.any
}

export default LiveViewMap;